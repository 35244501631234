import { template as template_173b9dd0dbc54f88ac639363eaa83f9a } from "@ember/template-compiler";
const FKControlMenuContainer = template_173b9dd0dbc54f88ac639363eaa83f9a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
