import { template as template_fb55bcf67aa441b19398e16266e9676d } from "@ember/template-compiler";
const FKLabel = template_fb55bcf67aa441b19398e16266e9676d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
